
import { Options, Vue } from 'vue-class-component'
import Overview from '@/components/Overview.vue' // @ is an alias to /src

@Options({
  components: {
    Overview
  }
})
export default class Expiring extends Vue {}
